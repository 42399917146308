import React, { useState } from "react";
import Nouislider from "nouislider-react";

const ResultFiltersDuration = (props) => {
  const [textValue, setTextValue] = useState({ min: 2, max: 30 });

  const onSlide = (render, handle, value, un, percent) => {
    setTextValue({ min: parseInt(value[0]), max: parseInt(value[1]) });
  };

  const onChange = (value) => {
    let val = { min: parseInt(value[0]), max: parseInt(value[1]) };
    props.handleFilters("duration", val);
  };

  return (
    <div className="filters-duration">
      <h4>Duration</h4>
      <div className="filters-slider">
        <Nouislider
          connect
          start={[2, 30]}
          behaviour="tap"
          range={{
            min: [2],
            max: [95],
          }}
          onSlide={onSlide}
          onChange={onChange}
        />
        <div className="row mt-4">
          <div className="col-6 text-secondary small">
            Min. {textValue?.min} Nights
          </div>
          <div className="col-6 text-secondary small text-end">
            Max. {textValue?.max} Nights
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultFiltersDuration;
