import React from "react";

import HotelIcon from "../assets/images/hotel-1.svg";
import TransportIcon from "../assets/images/transfer-1.svg";
import ActivityIcon from "../assets/images/sightseeing-1.svg";
import BusIcon from "../assets/images/bus-1.svg";
import TrainIcon from "../assets/images/train-1.svg";
import RailPassIcon from "../assets/images/railpass-1.svg";
import FerryIcon from "../assets/images/ferry-1.svg";

const InclusionsIcons = (props) => {
  return (
    <React.Fragment>
      {props.icon === "Hotel" && (
        <img src={HotelIcon} style={{ height: "18px" }} alt={props.icon} />
      )}

      {props.icon === "Activity" && (
        <img src={ActivityIcon} style={{ height: "18px" }} alt={props.icon} />
      )}

      {props.icon === "Transport" && (
        <img src={TransportIcon} style={{ height: "18px" }} alt={props.icon} />
      )}

      {props.icon === "Bus" && (
        <img src={BusIcon} style={{ height: "20px" }} alt={props.icon} />
      )}

      {props.icon === "Train" && (
        <img src={TrainIcon} style={{ height: "22px" }} alt={props.icon} />
      )}

      {(props.icon === "Rail pass" ||
        props.icon === "Rail Pass" ||
        props.icon === "Railpass" ||
        props.icon === "RailPass") && (
        <img src={RailPassIcon} style={{ height: "28px" }} alt={props.icon} />
      )}

      {props.icon === "Ferry" && (
        <img src={FerryIcon} style={{ height: "36px" }} alt={props.icon} />
      )}
    </React.Fragment>
  );
};

export default InclusionsIcons;
