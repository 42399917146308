import React, { useState, useEffect } from "react";
import Config from "../config.json";
import { useParams } from "react-router-dom";
import fetchData from "../services/requester";
import { currencyFormatter } from "../components/formatter";
import InclusionsIcons from "../components/inclusions-icons";
import { Link } from "react-router-dom";
import moment from "moment";
import IndicativePhoto from "../assets/images/result-city-indicative-photo.png";
import GridViewIcon from "../assets/images/result-grid-icon.svg";
import ListViewIcon from "../assets/images/result-list-icon.svg";
import CustomDropdown from "../components/custom-dropdown";
import ResultFilters from "../components/result-filters";
import ResultNotFound from "../assets/images/result-not-found.png";
import FilterIcon from "../assets/images/results-filter-icon-black.svg";
import PageLoader from "../assets/images/page-loader.svg";
import PlayVideoIcon from "../assets/images/play_video_icon_dark.svg";
import { Helmet } from "react-helmet";

const TripIdeasList = () => {
  const { liveMode } = Config;
  const params = useParams();
  const [data, setData] = useState([]);
  const [dataDefault, setDataDefault] = useState([]);
  const [info, setInfo] = useState("");
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState("list-view");
  const [filtersKey, setFiltersKey] = useState(1);
  const [pagination, setPagination] = useState({ pageNo: 0, pageSize: 10 });
  const [sorting, setSorting] = useState({
    sortBy: "duration",
    sortOrder: "asc",
  });
  const [filters, setFilters] = useState({
    duration: 0,
    maxDuration: 30,
    startType: "any",
    startDate: moment(),
    endDate: moment(),
    searchText: "",
    priceMin: 0,
    priceMax: 0,
    fixed: true,
    flexible: true,
    customized: true,
    categories: [],
    currency: "INR",
  });
  const [isMobile, setMobile] = useState(false);
  const [isPlayVideo, setPlayVideo] = useState(false);
  const [updateKey, setUpdateKey] = useState(1);
  const [video, setVideo] = useState();

  const getData = (sorting, pagination, filters) => {
    let req = "bundles/readyTripSearchRequest";

    let obj = {
      ...sorting,
      ...pagination,
      ...filters,
    };

    fetchData(req, obj, (res) => {
      let data = res?.readyTrips || [];
      let info = res?.pageInfo;

      data =
        data &&
        data?.map((x) => ({
          ...x,
          cityImageUrl:
            x?.cityImageUrl && x?.cityImageUrl[0]?.replace("w=200", "w=400"),
        }));

      setData(data || []);
      setInfo(info);
      getPagination(info);
      setLoading(false);
    });
  };

  const handleSorting = (sortBy) => {
    setLoading(true);
    let sort = sorting;

    switch (sortBy) {
      case "Price Low to High":
        sort.sortBy = "price";
        sort.sortOrder = "asc";
        break;

      case "Price High to Low":
        sort.sortBy = "price";
        sort.sortOrder = "decs";
        break;

      case "Duration Long to Short":
        sort.sortBy = "duration";
        sort.sortOrder = "decs";
        break;

      case "Duration Short to Long":
        sort.sortBy = "duration";
        sort.sortOrder = "decs";
        break;

      default:
        break;
    }
    setSorting(sort);
    getData(sort, { pageNo: 0, pageSize: 10 }, filters);
  };

  const handleFilters = (name, value) => {
    let filter = filters;

    switch (name) {
      case "duration":
        filter.duration = value?.min;
        filter.maxDuration = value?.max;
        break;

      case "budget":
        filter.priceMin = value?.min;
        filter.priceMax = value?.max;
        break;

      case "categories":
        filter.categories = value;
        break;

      case "destinations":
        filter.destinations = value;
        break;

      default:
        break;
    }

    let pagination = { pageNo: 0, pageSize: 10 };
    setLoading(true);
    setFilters(filter);
    setPagination(pagination);
    getData(sorting, pagination, filter);
    setMobile(false);
  };

  const clearFilters = () => {
    let pagination = { pageNo: 0, pageSize: 10 };
    let sorting = {
      sortBy: "duration",
      sortOrder: "asc",
    };
    let filters = {
      duration: 0,
      maxDuration: 30,
      startType: "any",
      startDate: moment(),
      endDate: moment(),
      searchText: "",
      priceMin: 0,
      priceMax: 0,
      fixed: true,
      flexible: true,
      customized: true,
      categories: [],
      currency: "INR",
    };

    setLoading(true);
    setPagination(pagination);
    setSorting(sorting);
    setFilters(filters);
    getData(sorting, pagination, filters);
    setFiltersKey(filtersKey + 1);
  };

  const handleScreen = () => {
    let { type } = params;
    let filter = filters;

    if (type) {
      type = type.split("-");

      if (type[0] === "budget") {
        filter.priceMin = Number(type[1]);
        filter.priceMax = Number(type[2]);
      }

      if (type[0] === "destination") {
        let destinations = [
          {
            name: type[1],
            type: type[2],
            code: type[3],
          },
        ];

        if (type.length > 4) {
          destinations.push({
            name: type[4],
            type: type[5],
            code: type[6],
          });
        }

        filter.destinations = destinations;
      }

      setFilters(filter);
      getData(sorting, pagination, filter);
    } else {
      getData(sorting, pagination, filters);
    }
  };

  const getOfflineData = () => {
    let { type } = params;
    let req = "/trip-ideas.json";
    let obj = "";

    fetchData(req, obj, (res) => {
      let data = res || [];
      let info = {
        current_page: 0,
        page_size: 10,
        total_records: data?.length,
      };

      data =
        data &&
        data?.map((x) => ({
          ...x,
          cityImageUrl:
            x?.cityImageUrl && x?.cityImageUrl[0]?.replace("w=200", "w=400"),
        }));

      setDataDefault(data || []);
      type ? handleOfflineScreen(data || []) : setData(data || []);
      setInfo(info);
      getPagination(info);
      setLoading(false);
      setFiltersKey(filtersKey + 1);
    });
  };

  const handleOfflineSorting = (sortBy) => {
    let sortedData = data;
    let sort = sorting;

    switch (sortBy) {
      case "Price Low to High":
        sortedData.sort((a, b) => a.startPrice - b.startPrice);
        break;

      case "Price High to Low":
        sortedData.sort((a, b) => b.startPrice - a.startPrice);
        break;

      case "Duration Long to Short":
        sortedData.sort((a, b) => b.duration - a.duration);
        break;

      case "Duration Short to Long":
        sortedData.sort((a, b) => a.duration - b.duration);
        break;

      default:
        break;
    }

    setSorting(sort);
    setUpdateKey(updateKey + 1);
    setData(sortedData);
  };

  const handleOfflineFilters = (name, value) => {
    let filteredData = data;
    let filter = filters;

    switch (name) {
      case "duration":
        filteredData = filteredData.filter(
          (x) => x?.duration >= value?.min && x?.duration <= value?.max
        );
        break;

      case "budget":
        filteredData = filteredData.filter(
          (x) => x?.startPrice >= value?.min && x?.startPrice <= value?.max
        );
        break;

      case "categories":
        if (value && value.length > 0) {
          let categoryPrefixes = value?.map((category) =>
            category?.toLowerCase()
          );

          function containsCategory(title) {
            let lowerCaseTitle = title?.toLowerCase()?.trim();
            return categoryPrefixes?.some((prefix) =>
              lowerCaseTitle?.includes(prefix)
            );
          }

          filteredData = dataDefault?.filter((item) =>
            containsCategory(item?.category)
          );
        } else {
          filteredData = dataDefault;
        }

        break;

      case "destinations":
        if (value && value.length > 0) {
          let cityPrefixes = value?.map((city) =>
            city?.name?.toLowerCase().slice(0, 5)
          );
          function containsCity(title) {
            let lowerCaseTitle = title?.toLowerCase()?.trim();
            return cityPrefixes?.some((prefix) =>
              lowerCaseTitle?.includes(prefix)
            );
          }
          filteredData = dataDefault?.filter((item) =>
            containsCity(item?.title)
          );
        } else {
          filteredData = dataDefault;
        }
        break;

      default:
        break;
    }

    setFilters(filter);
    setUpdateKey(updateKey + 1);
    setData(filteredData);
    setMobile(false);

    // Offline Loading
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const clearOfflineFilters = () => {
    let sorting = {
      sortBy: "duration",
      sortOrder: "asc",
    };
    let filters = {
      duration: 0,
      maxDuration: 30,
      startType: "any",
      startDate: moment(),
      endDate: moment(),
      searchText: "",
      priceMin: 0,
      priceMax: 0,
      fixed: true,
      flexible: true,
      customized: true,
      categories: [],
      currency: "INR",
    };
    setSorting(sorting);
    setFilters(filters);
    setUpdateKey(updateKey + 1);
    setData(dataDefault);
    setFiltersKey(filtersKey + 1);
  };

  const handleOfflineScreen = (data) => {
    let filter = filters;
    let { type } = params;

    type = type.split("-");

    if (type[0] === "budget") {
      data = data.filter(
        (x) =>
          x?.startPrice >= Number(type[1]) && x?.startPrice <= Number(type[2])
      );
      filter.priceMin = Number(type[1]);
      filter.priceMax = Number(type[2]);
    }

    if (type[0] === "destination") {
      let destinations = [
        {
          name: type[1],
          type: type[2],
          code: type[3],
        },
      ];

      if (type.length > 4) {
        destinations.push({
          name: type[4],
          type: type[5],
          code: type[6],
        });
      }

      let cityPrefixes = destinations?.map((city) =>
        city?.name?.toLowerCase()?.slice(0, 5)
      );
      function containsCity(title) {
        let lowerCaseTitle = title?.toLowerCase()?.trim(); // Trim the title
        return cityPrefixes?.some((prefix) => lowerCaseTitle?.includes(prefix));
      }
      data = data?.filter((item) => containsCity(item?.title));

      filter.destinations = destinations;
    }
    setData(data || []);
    setFilters(filter);
  };

  const changeView = () => {
    setView(view === "list-view" ? "grid-view" : "list-view");
  };

  const handleMobile = () => {
    setMobile(!isMobile);
  };

  const getPagination = (info) => {
    let pages = info?.total_records / info?.page_size;

    pages = Math.floor(pages) + (pages % 1 !== 0 ? 1 : 0);

    info.totalPages = [...Array(pages).keys()];
    setInfo(info);
  };

  const handlePagination = (clicked) => {
    setLoading(true);
    pagination.pageNo = clicked;
    getData(sorting, pagination, filters);
  };

  const handleVideo = (video) => {
    setVideo(video);
    setPlayVideo(!isPlayVideo);
  };

  const formatInclusions = (inclusions) => {
    let originalArray = inclusions;

    originalArray = originalArray.map((item) => item.trim());

    originalArray = originalArray.map((item) =>
      item === "Transfer" ? "Transport" : item
    );

    originalArray = [...new Set(originalArray)];

    originalArray = originalArray.filter((item) => item !== "Day at leisure");
    originalArray = originalArray.filter((item) => item !== "");

    return originalArray;
  };

  useEffect(() => {
    liveMode ? handleScreen() : getOfflineData();
    window.scrollTo(0, -1);
  }, []);

  return (
    <div className="trip-ideas-list">
      <Helmet>
        <title>
          Trip Ideas | Explore and Compare customizable holiday packages across
          the globe
        </title>
        <meta
          name="description"
          content="Get ready to explore the world like never before with Gottaholiday. Browse through holiday packages worldwide, discover exclusive deals, and enjoy seamless planning with our user-friendly platform."
        />
      </Helmet>
      <div className="container">
        <div className="title-info">
          <div className="row ">
            <div className="col-lg-6">
              <h2>Discover & Compare Holidays across the World</h2>
              <h5>
                {!loading && (
                  <React.Fragment>
                    Showing {1 + info?.current_page * info.page_size}-
                    {info?.current_page * info.page_size + data?.length}{" "}
                    holidays from {info?.total_records} holidays
                  </React.Fragment>
                )}
                {loading && (
                  <React.Fragment>Discovering Holidays</React.Fragment>
                )}
              </h5>
            </div>
            <div className="col-lg-6">
              <div className="d-flex align-items-center justify-content-end sorting-section">
                <button
                  className="btn filters-btn d-none"
                  onClick={handleMobile}
                >
                  <img
                    src={FilterIcon}
                    style={{ margin: "-4px 8px 0px 0px" }}
                    alt=""
                  />
                  Filter
                </button>
                <CustomDropdown
                  handleSorting={
                    liveMode ? handleSorting : handleOfflineSorting
                  }
                />
                <button className="btn change-view-btn" onClick={changeView}>
                  {view === "list-view" ? (
                    <React.Fragment>
                      <img src={GridViewIcon} alt="Grid View" />
                      <span>Grid</span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <img
                        style={{ marginLeft: "4px" }}
                        src={ListViewIcon}
                        alt="List View"
                      />
                      <span>List</span>
                    </React.Fragment>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row g-lg-4">
          <div
            className={"col-lg-3" + (isMobile ? " result-filters-mobile" : "")}
          >
            <ResultFilters
              handleFilters={liveMode ? handleFilters : handleOfflineFilters}
              clearFilters={liveMode ? clearFilters : clearOfflineFilters}
              key={filtersKey}
              filters={filters}
            />
          </div>
          <div className="col-lg-9">
            <div className={view} key={updateKey}>
              {!loading && (
                <div className="row">
                  {data?.map((x, key) => (
                    <div
                      key={key}
                      className={
                        view === "list-view" ? "col-lg-12" : "col-lg-4"
                      }
                    >
                      <div className="result-item">
                        <div className="row">
                          <div
                            className={
                              view === "list-view" ? "col-lg-9" : "col-lg-12"
                            }
                          >
                            <div className="row">
                              <div
                                className={
                                  view === "list-view"
                                    ? "col-lg-5"
                                    : "col-lg-12"
                                }
                              >
                                <div className="result-img-wrap">
                                  {x?.cityImageUrl && (
                                    <img
                                      src={x?.cityImageUrl}
                                      alt={x?.title}
                                      className="result-img"
                                    />
                                  )}
                                  {!x?.cityImageUrl && (
                                    <div className="result-img">
                                      <img
                                        src={IndicativePhoto}
                                        alt="Indicative Photo"
                                        className="result-img"
                                      />
                                    </div>
                                  )}
                                  {x?.videoLink && (
                                    <button
                                      className="btn itinerary-video-btn"
                                      onClick={() => handleVideo(x)}
                                    >
                                      <img src={PlayVideoIcon} alt="" />
                                    </button>
                                  )}
                                </div>
                              </div>
                              <div
                                className={
                                  view === "list-view"
                                    ? "col-lg-7"
                                    : "col-lg-12"
                                }
                              >
                                <div className="result-item-details">
                                  <h3 className="result-item-title">
                                    {x?.title}
                                  </h3>
                                  <div className="small">{x?.description}</div>

                                  <div className="results-inclusions">
                                    {formatInclusions(x?.inclusions)?.map(
                                      (y, key) => (
                                        <div
                                          key={key}
                                          className="results-inclusion-icon"
                                        >
                                          <InclusionsIcons icon={y} />
                                        </div>
                                      )
                                    )}
                                  </div>

                                  <div className="result-item-tag">
                                    Customizable
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className={
                              view === "list-view" ? "col-lg-3" : "col-lg-12"
                            }
                          >
                            <div className="result-item-price-dtl">
                              <div>
                                <div className="result-item-price-wrap">
                                  <div className="text-secondary small">
                                    Starting from
                                  </div>
                                  <h3 className="result-item-price">
                                    {currencyFormatter(parseInt(x?.startPrice))}
                                  </h3>
                                  <div className="text-secondary small">
                                    per person
                                  </div>
                                </div>
                                <div className="result-item-btn-wrap">
                                  <Link
                                    to={"/trip-ideas-details/" + x?.seoLink}
                                    className="btn btn-primary result-item-btn"
                                    target="_blank"
                                  >
                                    View Details
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {data?.length > 0 && info?.totalPages?.length > 1 && (
                    <div className={"pages" + (liveMode ? "" : " d-none")}>
                      <nav>
                        <ul className="pagination justify-content-center">
                          <li className="page-item">
                            <button
                              className={
                                "page-link" +
                                (info?.current_page === 0 ? " disabled" : "")
                              }
                              onClick={() => handlePagination(0)}
                            >
                              <span>&laquo;</span>
                            </button>
                          </li>

                          {info?.totalPages?.map((page, key) => (
                            <li
                              className={
                                "page-item" +
                                (info?.current_page === page ? " active" : "")
                              }
                              key={key}
                            >
                              <button
                                className="page-link"
                                onClick={() => handlePagination(page)}
                              >
                                {page + 1}
                              </button>
                            </li>
                          ))}

                          <li className="page-item">
                            <button
                              className={
                                "page-link" +
                                (info?.current_page ===
                                info?.totalPages?.length - 1
                                  ? " disabled"
                                  : "")
                              }
                              onClick={() =>
                                handlePagination(info?.totalPages?.length - 1)
                              }
                            >
                              <span>&raquo;</span>
                            </button>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  )}

                  {data?.length === 0 && (
                    <div className="col-lg-12">
                      <div className="no-result">
                        <div className="text-center">
                          <img src={ResultNotFound} alt="No results found" />
                          <h4>
                            No results found matching your search criterion.
                            <br />
                            Please change or reset all filters.
                          </h4>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {loading && (
                <div className="page-loader">
                  <img src={PageLoader} alt="Loading" />
                  Loading...
                </div>
              )}

              {!loading && isPlayVideo && (
                <div className="itinerary-video-wrap">
                  <iframe
                    className="itinerary-video-iframe"
                    src={
                      "https://www.youtube.com/embed/" +
                      video?.videoLink +
                      "&autoplay=1&mute=1"
                    }
                    key={video?.name}
                    title={video?.name}
                    allowFullScreen="allowFullScreen"
                  ></iframe>
                  <button
                    className="btn itinerary-video-close-btn"
                    onClick={handleVideo}
                  >
                    X
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripIdeasList;
