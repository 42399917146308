import React, { useEffect } from "react";
import { Routes, Route, useLocation, Link } from "react-router-dom";
import TagManager from "react-gtm-module";
import Header from "./components/header";
import Footer from "./components/footer";
import authRequest from "./services/auth-requester";
import Home from "./screens/home";
import TripIdeasList from "./screens/trip-ideas-list";
import TripIdeasDetails from "./screens/trip-ideas-details";
import Terms from "./screens/terms";
import ContactUs from "./screens/contact-us";
import TripSync from "./screens/trip-sync";
import WhatsappIcon from "./assets/images/whatsapp_icon.svg";
import AboutUs from "./screens/about-us";

const App = () => {
  const location = useLocation();

  const tagManagerArgs = {
    gtmId: "GTM-P9VMMDLC",
  };
  TagManager.initialize(tagManagerArgs);

  const getAuth = () => {
    authRequest((data) => {});
  };

  useEffect(() => {
    // getAuth();
  }, []);

  return (
    <React.Fragment>
      <Header />
      <div style={{ minHeight: "calc(100vh - 400px)" }}>
        <Routes>
          <Route key={1} path="/" element={<Home />} />
          <Route key={2} path="/trip-ideas" element={<TripIdeasList />} />
          <Route key={3} path="/trip-ideas/:type" element={<TripIdeasList />} />
          <Route
            key={4}
            path="/trip-ideas-details/:name"
            element={<TripIdeasDetails />}
          />

          <Route
            key={8}
            path="/terms-of-service"
            element={<Terms key={location.pathname} />}
          />
          <Route
            key={9}
            path="/privacy-policy"
            element={<Terms key={location.pathname} />}
          />
          <Route key={10} path="/contact-us" element={<ContactUs />} />
          <Route key={11} path="/thank-you" element={<ContactUs />} />
          <Route key={12} path="/trip-sync" element={<TripSync />} />
          <Route key={14} path="/about-us" element={<AboutUs />} />
        </Routes>
      </div>
      <Footer />
      <Link
        className="talktous-btn"
        to="https://web.whatsapp.com/send?phone=+919372999307"
        target="_blank"
        title="Talk to Us"
      >
        <img src={WhatsappIcon} alt="Talk to Us" />
      </Link>
    </React.Fragment>
  );
};

export default App;
