import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import AboutTeam from "../assets/images/about.png";
import HomeTestimonials from "../components/home-testimonials";
import { Link } from "react-router-dom";

const AboutUs = () => {
  const scroll = (pos) => {
    const section = document.querySelector(pos);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  useEffect(() => {
    window.scrollTo(0, -1);
  }, []);
  return (
    <div className="about-screen">
      <Helmet>
        <title>
          About Gottaholiday: Reach out to us for Expert Travel Assistance
        </title>
        <meta
          name="description"
          content="Have questions or need assistance? Contact Gottaholiday today for personalized support and expert advice on planning your dream vacation. Our team is here to help you every step of the way."
        />
      </Helmet>
      <div className="container">
        <div className="inner-screen-box">
          <div className="row">
            <div className="col-lg-5">
              <img src={AboutTeam} alt="About Gottaholiday" />
            </div>
            <div className="col-lg-7">
              <div className="about-info">
                <h1>About our Company</h1>

                <div>
                  <p>
                    Gottaholiday was started in 2016 with the objective of
                    helping customers plan customised holidays to any part of
                    the globe. Our clients like <b>our 3-step approach</b> to
                    holidays.
                  </p>

                  <p>
                    The team has a combined experience of <b>50+ years</b> in
                    holiday planning and execution. We can handle all your
                    requirements from the trip like air tickets, hotels, visas,
                    passport, travel insurance, foreign exchange, concert
                    tickets etc.
                  </p>

                  <p>
                    We are members of the Travel Agent Association of India
                    (TAAI) and Travel Agent Federation of India (TAFI). We are
                    recognised by the government of India.
                  </p>

                  <p>
                    Our mission is to provide customised holidays to any part of
                    the world to our customers worldwide. We want to help you
                    turn your dream destination into reality in the most
                    seamless fashion! We love to say{" "}
                    <b>"You think and we deliver!"</b>
                  </p>

                  <p>
                    We recommend you check out our{" "}
                    <Link to="/">client testimonials</Link> to know more about
                    our services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-steps">
          <h2>Our 3-Step Approach</h2>
          <div className="row mt-5">
            <div className="col-lg-4">
              <div className="inner-screen-box">
                <h3>1</h3>
                <p>
                  Itinerary planning Our experienced executive will talk to you
                  and understand your requirements. Accordingly, they will
                  quickly get back to you with a proposed itinerary. You can
                  make changes as per your preference till you arrive at your
                  ideal holiday plan.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="inner-screen-box">
                <h3>2</h3>
                <p>
                  Quotation & Booking Once you have closed the itinerary, we
                  will provide you with a quote offering the cheapest possible
                  price from local and online suppliers. Once you are happy, you
                  can sit back and relax. Our team will handle all the bookings
                  for you and also provide other insights like places to dine,
                  other attractions etc.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="inner-screen-box">
                <h3>3</h3>
                <p>
                  In-Trip Support We are there with you till you return home.
                  Our in-trip support team will be available throughout the trip
                  on Whatsapp for any request you have while traveling.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <HomeTestimonials /> */}
    </div>
  );
};

export default AboutUs;
