import React, { useState, useEffect } from "react";
import authRequest from "../services/auth-requester";
import fetchData from "../services/requester-live";
import moment from "moment";
import { currencyFormatter } from "../components/formatter";
import PageLoader from "../assets/images/page-loader.svg";

const TripSync = () => {
  const [data, setData] = useState([]);
  const [details, setDetails] = useState([]);
  const [info, setInfo] = useState("");
  const [loading, setLoading] = useState(true);
  const [isDownloading, setDownloading] = useState(false);

  const getAuth = () => {
    authRequest((token) => {
      token && getData();
    });
  };

  const getData = () => {
    let req = "bundles/readyTripSearchRequest";

    let obj = {
      sortBy: "duration",
      sortOrder: "asc",
      pageNo: 0,
      pageSize: 100,
      duration: 0,
      maxDuration: 30,
      startType: "any",
      startDate: moment(),
      endDate: moment(),
      searchText: "",
      priceMin: 0,
      priceMax: 0,
      fixed: true,
      flexible: true,
      customized: true,
      categories: [],
      currency: "INR",
    };

    fetchData(req, obj, (res) => {
      let data = res?.readyTrips || [];
      let info = res?.pageInfo;

      setData(data || []);
      setInfo(info);
      setLoading(false);
    });
  };

  const generateFile = (data, name) => {
    const jsonString = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const generateList = () => {
    generateFile(data, "trip-ideas.json");
  };

  const generateDetails = () => {
    setDownloading(true);
    data?.map((x) => getDetailsRes(x?.tripRefId));
  };

  const getDetailsRes = (tripRefId) => {
    let req = "packages/getTrip/" + tripRefId;

    let obj = "";
    fetchData(
      req,
      obj,
      (res) => {
        let newRes = {};
        newRes.name = res?.name;
        newRes.id = res?.id;
        newRes.startPrice = res?.startPrice;
        newRes.cities = res?.cities;
        newRes.itinerariesNew = res?.itinerariesNew;
        newRes.hotels = res?.hotels;
        newRes.transfers = res?.transfers;
        newRes.transports = res?.transports;
        newRes.activities = res?.activities;
        newRes.otherInclusionsList = res?.otherInclusionsList;
        newRes.maxNumberOfAdult = res?.maxNumberOfAdult;
        newRes.maxNumberOfChild = res?.maxNumberOfChild;

        // console.log(res?.id, res?.category);

        setDetails(details.push(res));
        if (details.length === data.length) {
          generateFile(details, "trip-details.json");
          setDownloading(false);
        }
      },
      "GET"
    );
  };

  useEffect(() => {
    getAuth();
    window.scrollTo(0, -1);
  }, []);

  return (
    <div className="inner-screen">
      <div className="container">
        <h1>
          Trip Sync{" "}
          {!loading && (
            <div className="d-flex" style={{ float: "right" }}>
              <button className="btn btn-primary btn-sm" onClick={generateList}>
                Download List
              </button>
              <button
                className="btn btn-primary btn-sm d-flex align-items-center ms-3"
                onClick={generateDetails}
              >
                {isDownloading && (
                  <div className="spinner-border spinner-border-sm text-light me-2"></div>
                )}
                Download Details
              </button>
            </div>
          )}
        </h1>

        <div className="row">
          <div className="col-lg-12">
            <div
              style={{
                background: "#fff",
                boxShadow: "0 0 12px #00000012",
                padding: "24px 24px 12px 24px",
                borderRadius: "8px",
              }}
            >
              {!loading && (
                <table className="table table-bordered rounded">
                  <thead>
                    <tr>
                      <th style={{ background: "rgba(0,0,0,0.05)" }}>Name</th>
                      <th style={{ background: "rgba(0,0,0,0.05)" }}>Id</th>
                      <th style={{ background: "rgba(0,0,0,0.05)" }}>
                        Destination
                      </th>
                      <th style={{ background: "rgba(0,0,0,0.05)" }}>
                        Starting from
                      </th>
                      {/* <th style={{ background: "rgba(0,0,0,0.05)" }}>
                        Download
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((x, key) => (
                      <tr key={key}>
                        <td>{x?.title}</td>
                        <td>{x?.tripRefId}</td>
                        <td>{x?.description}</td>
                        <td>{currencyFormatter(parseInt(x?.startPrice))}</td>
                        {/* <td>                          
                          <button
                            className="btn btn-link-primary btn-sm"
                            onClick={() =>
                              getDetailsRes(x?.tripRefId, x.tripRefId + ".json")
                            }
                            style={{ marginLeft: "16px" }}
                          >
                            Download
                          </button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {loading && (
                <div className="page-loader">
                  <img src={PageLoader} alt="Loading" />
                  Loading...
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripSync;
