import Config from "../config.json";
const { livePath } = Config;
const url = livePath;

const fetchData = (reqURL, reqOBJ, callback, reqMethod) => {
  let path = url + reqURL;

  return fetch(path, {
    method: reqMethod || "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    body: reqOBJ ? JSON.stringify(reqOBJ) : null,
  })
    .then((res) => res?.text())
    .then((data) => {
      try {
        callback(JSON.parse(data));
      } catch (e) {
        callback(data);
      }
    })
    .catch((e) => callback(e));
};

export default fetchData;
