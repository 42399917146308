import React, { useState, useEffect } from "react";
import fetchData from "../services/requester-live";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

const ResultFiltersDestination = (props) => {
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = (query) => {
    let req = "quickItinerary/searchDestinations/" + query + "/dummy";
    let obj = "";
    fetchData(
      req,
      obj,
      (data) => {
        let items = [];
        data.map((x) => x.data.map((y) => items.push({ ...y })));
        setOptions(items);
        setIsLoading(false);
      },
      "GET"
    );
  };

  const onChange = (item) => {
    props.handleFilters("destinations", item);
  };

  const filterBy = () => true;

  return (
    <div className="filters-destination">
      <h4>Destination</h4>

      <div className="custom-autocomplate">
        <AsyncTypeahead
          filterBy={filterBy}
          id="async-example"
          isLoading={isLoading}
          labelKey="name"
          minLength={3}
          onSearch={handleSearch}
          options={options}
          placeholder="City, Country, Continent"
          renderMenuItemChildren={(option) => (
            <div>
              <h5>
                {option?.name}
                <span>{option?.type}</span>
              </h5>
            </div>
          )}
          onChange={onChange}
          multiple
        />
      </div>
    </div>
  );
};

export default ResultFiltersDestination;
