import React from "react";
import HomeBanner from "../components/home-banner";
import HomeDestinations from "../components/home-destinations";
import HomeHowItWorks from "../components/home-how-it-works";
import HomeWhyTripDarwin from "../components/home-why-trip-darwin";
import HomeTestimonials from "../components/home-testimonials";
import HomeComingSoon from "../components/home-coming-soon";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <div className="home-screen">
      <Helmet>
        <title>
          Discover & Book your next Dream Holiday with Gottaholiday | Customized
          International Holidays with Great Prices & Expert Support
        </title>
        <meta
          name="description"
          content="Not sure where to travel? Discover your dream vacations with Gottaholiday! Explore & compare holiday packages for global destinations, customize your itineraries, and get expert advice & support throughout your journey."
        />
      </Helmet>
      <HomeBanner />
      <HomeDestinations />
      <HomeHowItWorks />
      <HomeWhyTripDarwin />
      <HomeTestimonials />
      <HomeComingSoon />
    </div>
  );
};

export default Home;
