import Config from "../config.json";
const { liveMode, livePath, localPath } = Config;
const url = liveMode ? livePath : window.location.origin + localPath;

const fetchData = (reqURL, reqOBJ, callback, reqMethod) => {
  let path = url + reqURL;

  return fetch(path, {
    method: reqMethod || (liveMode ? "POST" : "GET"),
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    body: reqOBJ ? JSON.stringify(reqOBJ) : null,
  })
    .then((res) => (liveMode ? res?.text() : res?.json()))
    .then((data) => {
      try {
        liveMode ? callback(JSON.parse(data)) : callback(data);
      } catch (e) {
        callback(data);
      }
    })
    .catch((e) => callback(e));
};

export default fetchData;
