import React from "react";
import "nouislider/distribute/nouislider.css";
import ResultFiltersDuration from "./result-filters-duration";
import ResultFiltersBudget from "./result-filters-budget";
import ResultFilterscategories from "./result-filters-categories";
import FilterIcon from "../assets/images/results-filter-icon.svg";
import ResultFiltersDestination from "./result-filters-destination";

const ResultFilters = (props) => {
  return (
    <div className="result-filters">
      <h3>
        <img src={FilterIcon} alt="" style={{ marginRight: "8px" }} />
        Filter Holidays
        <button className="btn btn-sm btn-link" onClick={props.clearFilters}>
          Clear All
        </button>
      </h3>

      <div className="filters-sec">
        <ResultFiltersDestination handleFilters={props.handleFilters} />
      </div>

      <div className="filters-sec">
        <ResultFiltersBudget handleFilters={props.handleFilters} {...props} />
      </div>

      <div className="filters-sec">
        <ResultFiltersDuration handleFilters={props.handleFilters} />
      </div>

      <div className="filters-sec">
        <ResultFilterscategories handleFilters={props.handleFilters} />
      </div>
    </div>
  );
};

export default ResultFilters;
