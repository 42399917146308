import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import fetchData from "../services/requester";
import SupportIcon from "../assets/images/details-help-icon.svg";
import WhatsappIcon from "../assets/images/whatsapp_icon.svg";
import ContactMapIcon from "../assets/images/contact-map.png";
import ContactEmailIcon from "../assets/images/contact-email.png";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const page =
    window.location.pathname === "/contact-us" ? "contact" : "thanks";
  const navigate = useNavigate();
  const [data, setData] = useState({
    fullName: "",
    address: "",
    contactNumber: "",
    query: "",
  });
  const [validation, setValidation] = useState({
    fullName: false,
    address: false,
    contactNumber: false,
    query: false,
  });
  const [updateKey, setUpdateKey] = useState(1);
  const [isShowLoading, setLoading] = useState(false);

  const onChange = (e) => {
    if (e.target.name === "fullName") {
      data.fullName = e.target.value;
    }
    if (e.target.name === "address") {
      data.address = e.target.value;
    }
    if (e.target.name === "contactNumber") {
      data.contactNumber = e.target.value;
    }
    if (e.target.name === "query") {
      data.query = e.target.value;
    }
    setData(data);
  };

  const handleSubmit = () => {
    setLoading(true);

    let req = "faq/submitDetails";
    let obj = {
      data,
    };

    fetchData(req, obj, (res) => {
      setLoading(false);
      navigate("/thank-you");
      window.scrollTo(0, 0);
    });
  };

  const handleValidation = () => {
    if (!data?.fullName || !data?.address || !data?.contactNumber) {
      validation.fullName = !data?.fullName ? true : false;
      validation.address = !data?.address ? true : false;
      validation.contactNumber = !data?.contactNumber ? true : false;
      setValidation(validation);
      setUpdateKey(updateKey + 1);
    } else {
      if (!emailValid(data?.address) || !phoneValid(data?.contactNumber)) {
        validation.address = !emailValid(data?.address) ? true : false;
        validation.contactNumber = !phoneValid(data?.contactNumber)
          ? true
          : false;
        setValidation(validation);
        setUpdateKey(updateKey + 1);
      } else {
        handleSubmit();
      }
    }
  };

  const emailValid = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const phoneValid = (phone) => {
    var phoneNum = phone.replace(/[^\d]/g, "");
    if (phoneNum.length > 6 && phoneNum.length < 15) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    window.scrollTo(0, -1);
  }, []);

  return (
    <div className="inner-screen">
      <Helmet>
        <title>
          Contact Gottaholiday: Reach out to us for Expert Travel Assistance
        </title>
        <meta
          name="description"
          content="Have questions or need assistance? Contact Gottaholiday today for personalized support and expert advice on planning your dream vacation. Our team is here to help you every step of the way."
        />
      </Helmet>
      <div className="container">
        <h1>Contact Us</h1>

        <div className="row">
          <div className="col-lg-8">
            <div className="inner-screen-box">
              {page === "contact" && (
                <div className="contact-form">
                  <div className="mb-3">
                    <label htmlFor="fullName" className="form-label">
                      Full Name *
                    </label>
                    <input
                      name="fullName"
                      id="fullName"
                      className="form-control"
                      onChange={onChange}
                      placeholder="Full Name"
                    />

                    {validation?.fullName && (
                      <p className="error-red">Name Required</p>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="address" className="form-label">
                      Email *
                    </label>
                    <input
                      name="address"
                      id="address"
                      className="form-control"
                      onChange={onChange}
                      placeholder="Email"
                    />
                    {validation?.address && (
                      <p className="error-red">Email Required</p>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="contactNumber" className="form-label">
                      Phone *
                    </label>
                    <input
                      name="contactNumber"
                      id="contactNumber"
                      className="form-control"
                      onChange={onChange}
                      placeholder="Phone Number"
                    />
                    {validation?.contactNumber && (
                      <p className="error-red">Phone Required</p>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="query" className="form-label">
                      How May We Help You?
                    </label>
                    <textarea
                      name="query"
                      id="query"
                      className="form-control"
                      onChange={onChange}
                      placeholder="Comments"
                    />
                  </div>

                  <button
                    className="btn btn-primary btn-lg mt-3"
                    onClick={handleValidation}
                  >
                    {isShowLoading && (
                      <div className="spinner-border spinner-border-sm text-light me-2"></div>
                    )}
                    Submit
                  </button>
                </div>
              )}

              {page === "thanks" && (
                <div className="text-center">
                  <h3 className="mt-3">
                    Your query has been submitted successfully. We will get in
                    touch with you soon.
                  </h3>

                  <p className="mt-4">
                    If you have any other queries in the meantime, please don't
                    hesitate to get in touch with us at{" "}
                    <Link to="mailto:packages@gottaholiday.com">
                      packages@gottaholiday.com
                    </Link>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-4 contact-page-support">
            <div className="trip-details-support mt-0">
              <h3>Connect on WhatsApp</h3>
              <p className="fw-bold" style={{ color: "#ffb010" }}>
                Message only
              </p>
              <div className="d-flex align-items-center mt-3">
                <img
                  src={WhatsappIcon}
                  style={{ height: "42px" }}
                  alt="Connect on WhatsApp"
                />
                <div className="ms-4">
                  <div className="mt-1">
                    <Link
                      to={
                        "https://wa.me/+919372999307?text=" +
                        encodeURIComponent("Hi")
                      }
                      target="_blank"
                    >
                      +91 9372999307
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="trip-details-support mt-3">
              <h3>Email us</h3>
              <div className="d-flex align-items-center mt-3">
                <img
                  src={ContactEmailIcon}
                  style={{ height: "36px" }}
                  alt="Chat to support"
                />
                <div className="ms-4">
                  <div className="mt-1">
                    <Link to="mailto:packages@gottaholiday.com" target="_blank">
                      packages@gottaholiday.com
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="trip-details-support mt-3">
              <h3>Visit us</h3>
              <div className="d-flex align-items-center mt-4">
                <img
                  src={ContactMapIcon}
                  style={{ height: "36px" }}
                  alt="Visit us"
                />
                <div className="ms-4">
                  <div className="mt-1">
                    <Link
                      to="https://www.google.com/maps/place/Darwin+Travel+Tech+Pvt.+Ltd./@19.1108937,72.866525,15z/data=!4m2!3m1!1s0x0:0x53f179c533a03bcf?sa=X&ved=1t:2428&ictx=111"
                      target="_blank"
                    >
                      View on Google Maps
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
