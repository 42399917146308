import React from "react";

const HomeWhyTripDarwin = () => {
  return (
    <div className="why-trip-darwin">
      <div className="container">
        <h2 className="text-center">
          <span>Why</span> Gottaholiday
        </h2>
        <h5 className="text-center">
          Discover why Gottaholiday is perfect for planning & booking your dream
          holiday. <br />
          Let's look at all the amazing benefits that await you!
        </h5>
        <div className="row g-3">
          <div className="col-lg-4">
            <div className="box-bg">
              <h3>Global Destinations</h3>
              <p>
                Explore trips worldwide easily: Discover holiday packages from
                countless destinations without the need to decide upfront. Your
                holiday - simplified.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="box-bg">
              <h3>Customize Your Journey</h3>
              <p>
                Make your trip your own: We help you find packages matching your
                travel needs & budget. Your personalized holiday starts here!
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="box-bg">
              <h3>Human Connection</h3>
              <p>
                Connect for personal help: Gottaholiday links online and offline
                smoothly. Talk to experts for personalized travel advice and
                support.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="box-bg">
              <h3>Fast Assistance</h3>
              <p>
                Quick responses: Gottaholiday answers faster than most. No more
                waiting, just fast help with your questions and travel needs.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="box-bg">
              <h3>Transparent Pricing</h3>
              <p>
                Clear costs, no surprises: We ensure transparent pricing with
                clear breakdowns. Enjoy your vacation without unexpected
                charges.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="box-bg">
              <h3>In-trip Assistance</h3>
              <p>
                Travel confidently with Gottaholiday by your side! Whether you
                need recommendations, changes, or in-trip support, we'll be
                there for you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeWhyTripDarwin;
