import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import DestinationJapan from "../assets/images/destination-japan.png";
import DestinationVietnam from "../assets/images/destination-vietnam.png";
import DestinationTurkey from "../assets/images/destination-turkey.png";
import DestinationGreece from "../assets/images/destination-greece.png";
import DestinationFrance from "../assets/images/destination-france.png";
import DestinationSwitzerland from "../assets/images/destination-switzerland.png";

const HomeDestinations = () => {
  const navigate = useNavigate();

  const handleExplore = (destination) => {
    let query = "";

    switch (destination) {
      case "JAPAN":
        query = "Japan-country-59eea4822f94933910ea4041";
        break;

      case "VIETNAM":
        query = "Vietnam-country-59eea4812f94933910ea3fdc";
        break;

      case "TURKEY & GREECE":
        query =
          "Turkey-country-59eea4812f94933910ea3fe8-Greece-country-59eea4822f94933910ea4053";
        break;

      case "FRANCE & SWITZERLAND":
        query =
          "France-country-59eea4822f94933910ea405e-Switzerland-country-59eea4822f94933910ea4077";
        break;

      default:
        break;
    }

    navigate("/trip-ideas/destination-" + query);
  };

  return (
    <div className="home-destinations">
      <div className="container">
        <div className="home-title">
          <h3>Trending Destinations</h3>
          <h2>Top Picks for Your Holiday!</h2>
          <p>
            Whether it's beautiful beaches, lively cities or natural wonders,
            we've got the best destinations for your dream holiday. <br />
            Let's plan your next adventure together. Exciting times await!
          </p>
          <Link to="/trip-ideas" className="btn btn-primary explore-btn">
            Explore More
          </Link>
        </div>

        <div className="home-destinations-wrap">
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-6">
                  <div
                    className="image-box"
                    onClick={() => handleExplore("JAPAN")}
                  >
                    <img src={DestinationJapan} alt="JAPAN" />
                    <h4>JAPAN</h4>
                    <h5>Starting from ₹91,713</h5>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="image-box"
                    onClick={() => handleExplore("VIETNAM")}
                  >
                    <img src={DestinationVietnam} alt="VIETNAM" />
                    <h4>VIETNAM</h4>
                    <h5>Starting from ₹36,372</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="image-box image-box-2"
                    onClick={() => handleExplore("TURKEY & GREECE")}
                  >
                    <div className="row g-0">
                      <div className="col-6">
                        <img src={DestinationTurkey} alt="TURKEY" />
                      </div>
                      <div className="col-6">
                        <img src={DestinationGreece} alt="GREECE" />
                      </div>
                    </div>
                    <h4>TURKEY & GREECE</h4>
                    <h5>Starting from ₹1,70,514</h5>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div
                    className="image-box image-box-2"
                    onClick={() => handleExplore("FRANCE & SWITZERLAND")}
                  >
                    <div className="row g-0">
                      <div className="col-6">
                        <img src={DestinationFrance} alt="FRANCE" />
                      </div>
                      <div className="col-6">
                        <img src={DestinationSwitzerland} alt="SWITZERLAND" />
                      </div>
                    </div>
                    <h4>FRANCE & SWITZERLAND</h4>
                    <h5>Starting from ₹2,01,826</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeDestinations;
