import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const HomeBanner = () => {
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("");

  const onChange = (e) => {
    setSelectedValue(e.target.value);
    handleExplore(e.target.value);
  };

  const handleExplore = (selectedValue) => {
    navigate(
      !selectedValue ? "/trip-ideas" : "/trip-ideas/budget-" + selectedValue
    );
  };

  return (
    <div className="home-banner">
      <div className="home-banner-bg">
        <div className="container position-relative">
          <h2>
            Discover Your Next <br />
            <span>Dream</span> Holiday
          </h2>
          <h3>Get ideas on where you can travel!</h3>
          <p>
            Start your perfect journey with Gottaholiday. Explore destinations
            with ease, plan & book the way you want, and relax. We're here to
            make your holidays stress-free & memorable.
          </p>
          <div className="banner-dropdown">
            <select className="form-select" onChange={onChange}>
              <option value="">Select Your Budget</option>
              <option value="1-50000">Under ₹50,000</option>
              <option value="50000-100000">₹50,000 - ₹1 Lac</option>
              <option value="100000-150000">₹1 Lac - ₹1.5 Lac</option>
              <option value="150000-200000">₹1.5 Lac - ₹2 Lac</option>
              <option value="200000-5000000">Over ₹2 Lac</option>
            </select>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => handleExplore("")}
            >
              Explore
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
