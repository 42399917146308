const url = "https://api-prod.tripdarwin.com/auth/";
const req = { username: "anonymous", password: "anonymous" };

const authRequest = (callback) => {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json, text/plain, */*",
      Accept: "application/json",
    },
    body: req ? JSON.stringify(req) : null,
  }).then((res) => {
    localStorage.setItem("token", res.headers.get("Authorization"));
    callback(res?.headers?.get("Authorization"));
  });
};

export default authRequest;
