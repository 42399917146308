import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import StickyBox from "react-sticky-box";

const Terms = () => {
  const scroll = (pos) => {
    const section = document.querySelector(pos);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useEffect(() => {
    window?.location?.pathname?.includes("privacy")
      ? scroll(".privacy")
      : window.scrollTo(0, -1);
  }, []);

  return (
    <div className="inner-screen">
      <div className="container">
        <h1>Terms & Conditions </h1>

        <div className="row">
          <div className="col-lg-9">
            <div className="inner-screen-box">
              <a name="a"></a>
              <h3 className="terms-a">A. General Notice</h3>
              <p>
                By using this website you confirm your acceptance to the below
                terms. If you do not fully understand or do not accept these
                terms &amp; conditions, please do not proceed with logging in to
                the gottaholiday.com portal and contact us for clarification.
              </p>
              <p>
                gottaholiday.com is a B2B Travel portal meant for travel agents
                to create, quote, and book holiday trips. All users of
                gottaholiday.com will be assigned a user account and a password
                to use the portal and the term 'user' shall apply to each user
                account. In case a travel agency has multiple user accounts,
                each user account shall comply with the terms below.
                gottaholiday.com reserves the right to suspend all user accounts
                assigned to the agency in the event of failure to adhere to the
                terms or violation of terms listed herein.
              </p>
              <h3 className="terms-b">B. Change In Our Terms And Conditions</h3>
              <p>
                gottaholiday.com, owned and operated by Darwin Travel Tech Pvt
                Ltd ("Darwin Tech") reserves the right to change and modify
                these terms and conditions at any time. Changes will be
                effective as soon as they are published on the website.
              </p>
              <h3 className="terms-c">C. Reservations</h3>
              <p>
                All trips and bookings must be made online. When the
                gottaholiday.com user has completed the booking online, it is
                the User/Agent's responsibility to check and make sure all the
                details on the prepaid voucher (such as hotel address, hotel
                information, room &amp; board types, pick up points, pick up
                times, city and country details, etc.) are correct. Once the
                reservations/requests are confirmed, gottaholiday.com's
                cancellation, amendment, no show policy is stated in
                cancellation policy before booking or at the booking
                confirmation. Payment and refund/discount policies will apply as
                described under the Service Access Agreement that is signed only
                with Credit/Deposit agencies.
              </p>
              <p>
                No amendments of any kinds (date changes, name changes, flight
                number change, vehicle type change) are accepted. Transfers are
                non-amendable. It is mandatory to enter valid and accurate
                flight details at the time of booking since it is not possible
                to amend.
              </p>
              <h3 className="terms-d">D. Groups</h3>
              <p>
                All rates displayed and bookings made are considered to be a
                individual booking. gottaholiday.com reserves the right to
                cancel any booking made for a group or, if determines, at its
                sole discretion, that the booking was/has been made for the
                purpose of holding space for a future sale. Rates displayed for
                each trip are valid for travellers whose names are provided
                during booking stage and cannot be applied to other groups. In
                case booking for a set of travellers is made by creating two
                separate trips, these will be considered as two separate
                bookings; separate rates as obtained through the quotation
                engine will be valid and cannot be combined and/or exchanged.
              </p>
              <h3 className="terms-e">E. Names Of Persons Travelling</h3>
              <p>
                It is important that the person making the booking must enter
                the correct names of all persons travelling. If TBA or another
                abbreviation has been entered as the guest's name, suppliers
                (such as Hotels or Tour operators) may reject the booking. The
                names of all persons travelling must be entered and the first
                name should be followed by the Family name.
              </p>
              <h3 className="terms-f">F. E-Mail Accounts</h3>
              <p>
                It is the responsibility of all gottaholiday.com users to ensure
                they have entered a correct email address. If our email has been
                sent to your Junk or Bulk mail folder, you can prevent this from
                happening again by opening our email and clicking on the 'not
                spam' button and adding that email address to your contact list
                / address book.
              </p>
              <h3 className="terms-g">G. Hotels</h3>
              <b>General</b>
              <p>
                The climate differences and energy saving rules of different
                countries might affect the heating systems at the hotel which is
                located at that particular area and/or country. For instance, in
                Italy the heating system can only be operated between Nov 15 and
                March 15 due to government regulations. Opening and closing
                dates of the swimming pools also might be affected by the
                climate and weather conditions. gottaholiday.com is not liable
                for the practices of the hotels regarding to these issues.
              </p>
              <b>Room Types</b>
              <p>
                It is the responsibility of the gottaholiday.com user making the
                booking to ensure that the room type booked will be suitable for
                the group of travellers. If more persons turn up at the hotel
                than the room can accommodate then the hotel are within their
                rights not to accept the booking and in this case, no refund
                will be made.
              </p>
              <p>
                While gottaholiday.com undertakes to ensure that your clients
                requested room type and smoking preference is available,
                gottaholiday.com cannot guarantee the actual make-up of the
                room. These requests are sent to the suppliers and are subject
                to availability.
              </p>
              <p>
                gottaholiday.com try to ensure that the hotel provides the room
                type(s) booked, however there may be occasions when instead of a
                double-bedded room a twin may be allocated or a double -bedded
                room instead of a twin. Please be aware that majority of
                European hotels provide 2 single beds pushed together to make a
                Double bed. While all room type preferences are forwarded to the
                suppliers, room allocation is done by the hotel and subject to
                availability at the time of check in.
              </p>
              <b>Special Requests</b>
              <p>
                Specific room types, smoking preferences and bedding types for
                double/twin rooms cannot be guaranteed and are subject to
                availability at the time of check in. It is always guaranteed
                that the room provided by the hotel will accommodate the number
                of Customer(s) booked.
              </p>
              <b>Check-In And Check-Outs</b>
              <p>
                Late Check-In: If a Customer is expected to arrive after the
                check-in time, please contact the hotel and inform them of
                Customer's arrival time. Some hotels have limited reception
                services after 21:00 hours. Failing to inform the hotel of a
                late arrival can result in the space being released.
              </p>
              <p>
                Early Check-Out: Early checkout related refund demands should be
                accompanied with hotel issued early checkout permission.
                However, the early checkout permission itself does not guarantee
                any refund, and the refund may be claimed only, if and when, our
                partner/hotel does not invoice gottaholiday.com.
              </p>
              <h3 className="terms-h">H. Information And Facilities</h3>
              <p>
                All information regarding services listed on gottaholiday.com is
                presented on an as-is basis from the supplier and is not
                physically verified by gottaholiday.com. The actual state of
                services may vary from what is displayed and gottaholiday.com
                accepts no liability regarding the accuracy of information as
                these have been provided to us and accepted in good faith.
              </p>
              <b>Hotel information</b>
              <p>
                While gottaholiday.com strives to present hotel information as
                accurately as possible, we are not responsible for the accuracy
                of this information or for any facilities that may or may not be
                available at the hotel during your client's stay or not suited
                to your client's individual tastes and preferences.
                Redecoration/ renovations and maintenance are necessary to the
                upkeep of the hotel and may take place without prior warning,
                however the hotelier will endeavour to keep inconvenience to a
                minimum. The effects of normal wear and tear can be expected in
                a hotel and these are beyond our control. gottaholiday.com
                cannot accept responsibility for any disturbance or
                inconvenience to your client beyond the hoteliers control nor
                for accidents or loss in a hotel caused by hotel management or
                staff.
              </p>
              <p>
                Hotel and guest room photographs are provided to give a general
                overview of the hotel. Guest room photographs may be of a
                different category to the one you book and not identical to the
                room your client(s) are allocated at the hotel.
              </p>
              <p>
                Hotels require one of the guests to be at least 18 years old. In
                some states of America there are higher age limits. If you have
                booked a hotel in the Unites States and the travellers are under
                25 years of age, please contact the hotel directly for
                clarification.
              </p>
              <b>Locality or Map Information</b>
              <p>
                Location and Maps are provided for information purposes only.
                While gottaholiday.com strives to present location and map
                information as accurately as possible, we do not accept any
                responsibility for the accuracy of this information or for any
                errors and/or omissions. We suggest that you contact the
                supplier or provider (ex. hotel) directly to obtain the most
                current and complete location information and directions.
              </p>
              <b>Categories/ Local Classification and Star Ratings</b>
              <p>
                gottaholiday.com displays categories, classifications and other
                filters based on inputs received from its suppliers. These aim
                to provide user convenience in search. Hotel Star ratings aim to
                give a general overview of the quality of the hotel and
                approximate level of facilities, services and amenities
                available. However, these categories, classifications and rating
                system is presented on an as-is basis from the supplier and is
                not verified by gottaholiday.com. Further, this information may
                vary based on the suppliers and the geography. For example, a 5
                Star Bangkok hotel will not be the same as a 5 Star London
                hotel. gottaholiday.com is not responsible for the categories,
                classification and Star ratings as these have been provided to
                us and accepted in good faith.
              </p>
              <b>Vehicle / transit Information</b>
              <p>
                Vehicle size, seating and pictures are usually presented to
                users to help them make informed choices regarding their transit
                arrangements. However, the actual vehicles allotted depend upon
                a variety of factors including availability, cost and weather /
                environmental considerations. Hence the actual vehicle provided
                to you or the facilities therein may vary from what is presented
                on gottaholiday.com at the time of booking. In case this results
                in inconvenience, travellers or users may file a complaint with
                gottaholiday.com prior to or at the time of or after the transit
                and efforts will be made by our team to correct the problem
                during the same tour for other transits or claim compensation
                from the supplier or provider for the same. For more details
                kindly refer to Section O Complaints. <br />
              </p>
              <h3 className="terms-i">I. Transfers</h3>
              <p>
                For the transfers at booking the below general terms will be
                applied:
              </p>
              <ul>
                <li>
                  Each transfer service listed has its own release period as
                  displayed on the calendar.
                </li>
                <li>
                  Once the transfer booking is completed, a voucher with the
                  reference number will be provided. The voucher must be printed
                  and presented as proof of reservation/booking. The voucher
                  will show all the information necessary to reach the boarding
                  point and will also show a contact telephone number for
                  checking the Booking and informing about contingencies.
                </li>
                <li>
                  The destination and pick-up addresses on the voucher are the
                  addresses where the passenger will be delivered and picked up.
                </li>
                <li>
                  gottaholiday.com will not assume any responsibility in
                  omissions and typo errors, which may result in any
                  interruption of the service.
                </li>
                <li>
                  In case the booking is made for a round trip transfer, 2 (two)
                  separate vouchers must be printed and presented to the driver
                  /guide /transfer assistant on the corresponding service dates.
                </li>
                <li>
                  gottaholiday.com advises the passenger/Customer to request
                  transfer service confirmation 24 hours before service time.
                  Transfers are not guaranteed, if no confirmation is requested
                  or confirmation is not available.
                </li>
                <li>
                  No amendments of any kinds (date changes, name changes, flight
                  number change, vehicle type change) are accepted. Transfers
                  are non-amendable. It is mandatory to enter valid and accurate
                  flight details at the time of booking since, it is not
                  possible to amend.
                </li>
                <li>
                  No reimbursement to the final consumer shall be made in the
                  event of a 'no-show' without prior consultation with and
                  express authorization from gottaholiday.com, who shall inform
                  You of charges payable which, may vary up to 100% of the
                  amount of the reservation. Information for the Booking of the
                  transfer service is provided by you
                </li>
                <li>
                  IMPORTANT - When the gottaholiday.com user has completed the
                  booking online, they must check and make sure all the details
                  on the pre paid voucher (such as hotel address, hotel info,
                  city and country details) are correct.
                </li>
              </ul>
              <h3 className="terms-j">
                J. Payments, Refunds, Charges And Rates
              </h3>
              <p>
                Payment terms for each package are different depending upon its
                composition. These terms are communicated to the user prior to
                booking the package and the user is expected to review them.
                Proceeding with the booking shall be deemed as the user having
                accepted the payment terms. The payment terms include the
                following types of payments:
              </p>
              <ul>
                <li>
                  <strong>Total Package Price</strong>: This is the total amount
                  payable for the package including taxes
                </li>
                <li>
                  <strong>Total Advance</strong>: Amount payable before booking
                  the package{" "}
                </li>
                <li>
                  <strong>Refundable Advance</strong>: Part of 'Advance' which
                  can be refunded in the event of cancellation
                </li>
                <li>
                  <strong>Non-refundable Advance</strong>: Part of 'Advance'
                  which will be forfeited in the event of cancellation
                </li>
                <li>
                  <strong>Pay Later by [date]</strong>: Amount which can be paid
                  after booking the package. The <strong>[date]</strong> value
                  is the deadline by which the 'Pay Later' amount shall have to
                  be paid, failing which the package will be automatically
                  cancelled by the system
                </li>
              </ul>
              <p>
                In any case, all pending payment for all trips booked on
                gottaholiday.com shall be made prior to the travel date. The
                same shall be enforced through messages and updates on
                gottaholiday.com and also via emails. After booking of the
                package, the user will be shown various amounts such as:
              </p>
              <ul>
                <li>
                  <strong>Payable</strong>: Total Payable amount towards the
                  package
                </li>
                <li>
                  <strong>Received</strong>: Payment already received as advance
                  or further payment after advance
                </li>
                <li>
                  <strong>Unconfirmed Payment</strong>: Payment which the user
                  has claimed to have paid but not yet confirmed by
                  gottaholiday.com
                </li>
                <li>
                  <strong>Pending Payable</strong>: Payment yet to be made by
                  the user including any unconfirmed payments
                </li>
              </ul>
              <p>
                The responsibility of making the payment in time shall rest upon
                the user, failing which gottaholiday.com shall cancel all
                bookings made on its portal. In case the user has made partial
                payments and full payment is not received before the travel
                date, gottaholiday.com shall not be able to refund of the
                partial amount paid as charges may have to be paid to the
                supplier for towards cancellations.
              </p>
              <b>Currency Rates and Fluctuations </b>
              <p>
                Currency exchange rates generally change on daily basis
                depending on the fluctuations in the market. We integrate
                suppliers into the system based on the currency provided by them
                and convert these currencies in real time to desired user
                currency. gottaholiday.com reserve the right to update rates
                displayed on the website depending on any market fluctuations.
              </p>
              <p>
                However, changes relating to currency exchange rate fluctuations
                will not affect the rates of an already confirmed booking.
                Likewise, once a booking has been booked and confirmed at the
                rates you have accepted, there is no refund for any difference
                in rates due to exchange rate fluctuations. As a corollary, upon
                cancellation, the exchange rates at the time of cancellation
                shall apply and the difference between exchange rates at the
                time of booking and cancellations shall have to be absorbed by
                the user.
              </p>
              <p>
                All rates are valid for the leisure market only.
                gottaholiday.com will not accept responsibility for any booking
                if it becomes known that a client is not travelling for leisure
                purposes. Hotels may refuse to honour our contract rate and
                charge rack rate to the clients directly. Rates include
                applicable hotel taxes. Tourist/local/city taxes which generally
                include the use of local services may not be included. If city
                tax needs to be paid at the hotel, this info might be shown in
                Remarks and on the voucher.
              </p>
              <b>Chargeback &amp; Fraud</b>
              <p>
                "Chargeback" means any disputed credit or debit card sale that
                is returned to the acquirer bank for reimbursement of the
                cardholders account. Chargebacks can be procedural
                (issuer-initiated chargebacks) or substantive
                (cardholder-initiated chargebacks).
              </p>
              <p>
                Without prejudice to the right of the suppliers to appeal a
                reported Chargeback with the acquiring bank, the user explicitly
                agrees to accept any such reported Reversed Payment, Penalties
                and/or other fees and expenses reported by gottaholiday.com
                and/or Darwin Tech, provided that Darwin Tech notifies user of
                any such Chargeback, Penalty or other fee or expense.
              </p>
              <p>
                Upon notification of a Chargeback, the user may initiate a
                challenge to the Chargeback by forwarding all Transaction
                information to Darwin Tech within permitted time period by email
                (e.g. signed order form to provide proof of the cardholder's
                authorization of the transactions or acceptance of goods). Upon
                receipt, Darwin Tech will forward this information to the
                acquiring bank. The acquiring bank will make the final decision
                as to the validity of the Chargeback.
              </p>
              <p>
                In order to protect Darwin Tech against any form of fraud,
                unauthorized use of the Services or prohibited activities,
                including, but not limited to, violation of the Rules and misuse
                of a user Account, Darwin Tech will have the right to
                immediately modify and/or suspend some or all of the provision
                of Services to user by email notice. Darwin Tech shall use this
                right of modification and/or suspension of the Services in good
                faith, with restraint, when Darwin Tech reasonably suspects or
                has evidence that fraud is or may be occurring or
                gottaholiday.com reasonably determines that the user (Agency) is
                wilfully dishonest, fraudulent and/or engages in misconduct with
                respect to its business or affairs or an acquirer, card
                association, payment method supplier, governmental or
                supervisory authority requires or advises Darwin Tech to modify,
                suspend or terminate the Services or a law, rule, banking
                regulation including, but not limited to, currency restriction
                from local and/or central banks mandates modification,
                suspension or termination of the Services.
              </p>
              <b>Credit Card Usage</b>
              <p>
                Our company name will appear as Darwin Travel Tech on the credit
                card statement of the credit card used to make the booking. If a
                gottaholiday.com user has used their clients credit card to make
                the booking then it is the responsibility of the user to inform
                their customer of this in order to avoid any misunderstandings.
                gottaholiday.com reserves the right to correct any pricing or
                displayed errors and/or omissions. This includes errors and/or
                omissions which have been entered by a hotelier or local agent.
                In the event of a price error and/or omission, we will offer you
                the choice of either keeping the booking at the correct rates,
                cancelling the booking or subject to availability we will offer
                you a suitable alternate hotel.
              </p>
              <b>Local Market Rates</b>
              <p>
                Not all of the hotel rates are displayed on the gottaholiday.com
                website are applicable for the local market. In addition, some
                other markets may be affected and in such cases the hotel is
                within their rights to change the rates without prior notice.
              </p>
              <p>
                The user agrees that passenger nationality declaration is
                mandatory and must be determined by selecting Client Nationality
                at the time of booking. This information must be in accordance
                with the passenger passport. False declaration of passenger
                nationality may cause consequences for which we cannot be held
                liable. If you do not change Client Nationality, the user
                Agency's Nationality will be taken as passenger nationality by
                default. In case, any financial damage occurs due to false
                nationality declaration, it will be covered by the users towards
                customers or to us in full.
              </p>
              <b>Resort and International Transaction Fees</b>
              <p>
                The majority of credit card providers charge some sort of
                Transaction fee. When such fee is levied on the merchant (i.e.
                gottaholiday.com), it is paid for (absorbed) by us as a part of
                operational costs.
              </p>
              <p>
                However, credit card providers may also charge a separate fee
                directly to the user especially when the charge for your booking
                has been processed outside of the country that you reside in.
                Please note that the charge has not been passed on by
                gottaholiday.com and that gottaholiday.com cannot be held
                responsible for any international transaction charges passed on
                by your or your clients credit card issuer.
              </p>
              <p>
                Some hotels or resorts charge additional fee or local municipal
                taxes which must be paid to the hotel directly. gottaholiday.com
                is not responsible for resort fee charges and have no control
                over their implementation - the traveller shall need to pay
                these directly to the requesting property.
              </p>
              <b>Additional Charges Levied by the Supplier</b>
              <p>
                gottaholiday.com has no control over any extra charges that a
                supplier or hotel may decide to their implement for guest room
                incidentals such as air conditioning, safe, mini fridge, hire of
                television remote etc. Any such charges must be paid direct to
                the hotel and gottaholiday.com cannot be held responsible for
                any incidental charges passed on by the hotel.
              </p>
              <p>
                Likewise, gottaholiday.com has no control over any fees that a
                hotel may pass on for luggage storage, Sauna, spa and swimming
                pool use, car parking fees etc. During the festive season,
                essentially Christmas and the New Year, there are hotels which
                impose a compulsory gala and guests must pay any supplement for
                the gala dinner. Our company is not always informed about Gala
                dinner supplements and gottaholiday.com cannot be held
                responsible for any gala supplements passed on by the hotel.
              </p>
              <h3 className="terms-k">K. Cancellation and Amendment</h3>
              <p>
                We prefer that all cancellations and amendments are made on the
                gottaholiday.com website. Alternatively, you can contact the
                gottaholiday.com booking Team via e-mail before the cancellation
                deadline for that specific booking/hotel. We do not accept
                cancellations or amendments over the telephone.
              </p>
              <p>
                gottaholiday.com shall not be responsible for delays arising out
                of the Booking helpdesk not being available outside office
                hours.
              </p>
              <p>
                gottaholiday.com will not be bound by nor responsible for any
                changes and cancellations made directly with the hotel.
              </p>
              <p>
                All request for cancellations that are not made in proper time
                frame are subject to cancellation fee determined by
                gottaholiday.com.
              </p>
              <p>
                It is important to note that only one amendment per package will
                be accepted. If you require further amendments, please cancel
                and re-book the package with new details. When you re-book, new
                rates may be applicable.
              </p>
              <p>
                During special event periods, certain dates and early bird type
                bookings, the hotel may pass on a different cancellation policy
                of which you will be informed as soon as gottaholiday.com are
                notified.
              </p>
              <p>
                In certain cases, name changes are not permitted and the booking
                may need to be cancelled and re-booked. In this case new rates
                may apply.
              </p>
              <p>
                Any booking which offers free nights or early booking savings
                are liable to certain restrictions which we will inform you
                about as soon as gottaholiday.com are notified. Generally, these
                restrictions are; No name changes and no extension or reduction
                of nights. If you have booked a stay that includes 1 night free
                and your client decides to cancel- The free night will not be
                refunded because this is free.
              </p>
              <p>
                Please note that nightly room rates might increase after a
                confirmed amendment even if you decrease the number of nights.
                Similarly, tour rates or transfer rates may change if you
                decrease the number of travellers in a vehicle - for example
                booking an 18-seater coach for 18 people and later reducing the
                number of people to 15 may increase the per person pricing for
                this coach. Changes may also be due to changes in currency
                exchange rates or promotions on the original booking date. If
                amendments are permitted, amendment conditions as specified by
                our suppliers may apply. Changes to arrival/departure date, room
                type or name change or where exclusive offers were applied at
                the time of the booking may result in the item re-pricing.
              </p>
              <h3 className="terms-l">L. Special Events Period</h3>
              <p>
                During Special Event Periods, suppliers may pass on a different
                cancellation policy of which user will be informed as soon as
                gottaholiday.com is notified. Also, supplier(s) may not honor
                gottaholiday.com's usual reduced rates, which means that the
                price the user pays may be higher than the published rate. This
                can happen at any time when availability is scarce. During
                special event periods, certain dates and early bird type
                bookings, the supplier may pass on a different cancellation
                policy of which you will be informed as soon as gottaholiday.com
                is notified.
              </p>
              <h3 className="terms-m">M. Proof of Id</h3>
              <p>
                To protect your gottaholiday.com account , when a booking has
                been made by a 3rd party user gottaholiday.com reserves the
                right to request proof of ID from the credit cardholder as well
                as a signed authorisation form.
              </p>
              <p>
                This is not requested in all cases, but may be requested in
                certain cases on discretion of gottaholiday.com's risk
                management team. This is a security measure not intended to
                cause any inconvenience but merely to protect the credit card
                holder against any credit card misuse.
              </p>
              <h3 className="terms-n">N. Relocation of Your Clients</h3>
              <p>
                If the original hotel booked is closed, over booked or has
                maintenance problems and /or cannot provide the room(s) booked.
                You accept that the hotelier or supplier is responsible for
                finding you alternate accommodation of a similar standard. Where
                we have prior notice gottaholiday.com will contact you by email.
                In case of any relocation, gottaholiday.com is responsible only
                to offer one alternate hotel. More than one alternate hotel will
                be optional. gottaholiday.com constantly tries to find hotels in
                the desired area but this situation can change according to the
                consistency of the city (big events, conferences, festivals can
                sometimes take place in the city).
              </p>
              <p>
                gottaholiday.com accepts no liability for any losses or costs
                that might occur because of re-location as this is completely
                beyond our control.
              </p>
              <h3 className="terms-o">O. Complaints</h3>
              <p>
                Any complaint which cannot be resolved by the supplier must be
                notified to us by email. All complaints must be notified to us
                within 5 days of the check out date. Where possible a copy of
                the complaint must also be submitted to the supplier or
                provider. Complaints will be forwarded by gottaholiday.com to
                the relevant party and responses / refunds sought on your
                behalf. Response times do vary from between 5-20 working days
                depending upon the nature of the query in question.
                gottaholiday.com team shall attempt to resolve your complaint
                upon receipt and in case required, attempt to get you a refund.
                gottaholiday.com shall pass on such refunds to the user, however
                gottaholiday.com cannot guarantee a refund and shall not be held
                responsible for the refund and accepts no liability of
                disbursing payments or refunds on behalf of our suppliers.
              </p>

              <h3 className="terms-p">P. Visa Support</h3>
              <p>
                gottaholiday.com will not be obliged to request any visa
                documents from the hotel for any bookings which have not been
                paid for in full. gottaholiday.com will request these visa
                documents from the hotel upon your acceptance of a charge of INR
                2500 per peron in the event that you cancel the booking.
                gottaholiday.com will do the best to accommodate your request
                but it is not guaranteed that the hotel will respond to your
                visa document request. gottaholiday.com has no control over any
                charges that a hotel may implement for visa support documents.
              </p>
              <p>
                In the event that a hotel implements charges for visa support
                documents, gottaholiday.com expects that the agency will make
                the relevant payment direct to the hotel.
              </p>
              <h3 className="terms-q">Q. Promotions and Promotional Rates</h3>
              <p>
                Promotions are offered in good faith only. Some promotions are
                more popular than others in which case the rooms featured will
                be sold out much quicker. gottaholiday.com reserves the right to
                modify or change any promotion or offer at any time without
                notice. Please make a search for your requested dates for the
                most current rates.
              </p>
              <h3 className="terms-r">R. General Terms and Conditions</h3>
              <p>
                gottaholiday.com shall not be liable for any failure in service
                relating from uncontrollable circumstances such as flood,
                earthquake, riot, terrorist acts, acts of governments or
                authority change in a country, bad weather conditions etc.
              </p>
              <p>
                Telephone calls to gottaholiday.com may be recorded to enable us
                to monitor and improve our services. Please contact us at
                packages@gottaholiday.com if you do not want to receive any
                promotional emails/ Newsletters or special deals.
              </p>
            </div>

            <div className="inner-screen-box mt-4">
              <h2 className="mb-4 privacy">Privacy Policy</h2>
              <p>
                This Privacy Policy is applicable to this website
                gottaholiday.com and the mobile app (both referred to as
                "gottaholiday.com" for purpose of simplicity). This privacy
                policy does not apply to the any websites of our users (travel
                agents or others), corporate affiliates or to any other third
                parties, even if their websites are linked to the site. We
                recommend you review privacy policies of the other parties with
                whom you interact.
              </p>
              <p>
                By browsing, visiting, accessing and/or using the services on
                this gottaholiday.com (or searching for any of the pages on
                gottaholiday.com), the user ("You") and your customers
                ("Travellers") explicitly consent and agree to our Privacy
                Policy laid out herein. You also agree that the information
                furnished by you is lawful, true and correct and does not
                violate or infringe any laws. In case of any violations,
                infringement, furnishing of wrongful or unauthorized
                information, gottaholiday.com and Darwin Tech shall not be
                liable for the same.
              </p>
              <p>
                We encourage you to review our Privacy Policy, and familiarise
                with it. Please note that we review our Privacy Policy from time
                to time, and we may make periodic changes to the policy in
                connection with that review. Therefore, you may wish to bookmark
                this page and/or periodically review this page to make sure you
                have the latest version of the Privacy Policy.
              </p>
              <b>What information does gottaholiday.com collect </b>
              <p>
                On accessing gottaholiday.com, certain information about you,
                such as :
              </p>
              <ul>
                <li>
                  Login Information refers to user account (user ID assigned to
                  you) and associated password{" "}
                </li>
                <li>
                  Personally Identifiable Information refers to name(s), age,
                  nationality and contact details which may include e-mail
                  address or contact number(s) etc.
                </li>
                <li>
                  Transactional Information refers to financial information such
                  as Bank account or credit card or debit card or other payment
                  instrument details etc.
                </li>
                <li>
                  Travel information refers to includes information about the
                  travel, bookings, co-passengers, travel preferences etc.
                </li>
                <li>
                  Other information that may be requested or generated due to
                  your actions on gottaholiday.com.
                </li>
              </ul>
              <b>What do we use your information for?</b>
              <ul>
                <li>
                  To perform bookings, ticketing and payment transactions for
                  the services available on gottaholiday.com;
                </li>
                <li>
                  To contact you or the traveller with respect to the booking,
                  cancellation, or other information required which may not be
                  available;{" "}
                </li>
                <li>
                  To contact the you or the traveller for support prior, during
                  or after the travel with respect to any queries, grievances or
                  complaints raised;{" "}
                </li>
                <li>
                  To personalize your experience and give you better service and
                  improve the user experience friendliness of gottaholiday.com;{" "}
                </li>
                <li>
                  To send emails, SMS and other communications for providing
                  services, responding to inquiries, and/or other requests or
                  questions;
                </li>
                <li>
                  To respond to queries of the authorities in regard to your
                  accessing of gottaholiday.com and services availed by you;
                </li>
                <li>Website traffic analysis;</li>
                <li>Automatic Logging of Session Data.</li>
              </ul>
              <p>
                We acknowledge that, you are the ultimate owner of your Personal
                Information and we shall not claim ownership or use this
                information for any purpose other than stated above. You
                acknowledge that you are solely responsible for maintaining the
                secrecy of your passwords, and your user account information.
              </p>
              <p>
                We do not sell, trade, or otherwise transfer to outside parties
                your personally identifiable information. However, we do
                transmit your information to trusted third parties who assist us
                in operating our website, conducting our business, or servicing
                you, so long as those parties agree to keep this information
                confidential. We may also the release your information when we
                believe release is appropriate to comply with the law, enforce
                our site policies, or protect ours or others' rights, property,
                or safety. However, other non-personally identifiable visitor
                information may be provided to other parties for marketing,
                advertising, or other uses.
              </p>
              <p>
                For concerns regarding our Privacy Policy or related issues,
                write to us at packages@gottaholiday.com.
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <StickyBox offsetTop={116} offsetBottom={20}>
              <div className="inner-screen-box inner-screen-nav">
                <h3>
                  <Link to="#/terms#tnc">Terms &amp; Conditions</Link>
                </h3>
                <ol type="A">
                  <li>
                    <Link onClick={() => scroll(".terms-a")}>
                      General Notice
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scroll(".terms-b")}>
                      Change In Our Terms And Conditions
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scroll(".terms-c")}>Reservations</Link>
                  </li>
                  <li>
                    <Link onClick={() => scroll(".terms-d")}>Groups</Link>
                  </li>
                  <li>
                    <Link onClick={() => scroll(".terms-e")}>
                      Names Of Persons Travelling
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scroll(".terms-f")}>
                      E-Mail Accounts
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scroll(".terms-f")}>Hotels</Link>
                  </li>
                  <li>
                    <Link onClick={() => scroll(".terms-f")}>
                      Information And Facilities
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scroll(".terms-f")}>Transfers</Link>
                  </li>
                  <li>
                    <Link onClick={() => scroll(".terms-f")}>
                      Payments, Refunds, Charges And Rates
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scroll(".terms-k")}>
                      Cancellation and Amendment
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scroll(".terms-l")}>
                      Special Events Period
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scroll(".terms-m")}>Proof of Id</Link>
                  </li>
                  <li>
                    <Link onClick={() => scroll(".terms-n")}>
                      Relocation of Your Clients
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scroll(".terms-o")}>Complaints</Link>
                  </li>
                  <li>
                    <Link onClick={() => scroll(".terms-p")}>Visa Support</Link>
                  </li>
                  <li>
                    <Link onClick={() => scroll(".terms-q")}>
                      Promotions and Promotional Rates
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scroll(".terms-r")}>
                      General Terms and Conditions
                    </Link>
                  </li>
                </ol>
                <h3 className="mt-3">
                  <Link onClick={() => scroll(".privacy")}>Privacy Policy</Link>
                </h3>
              </div>
            </StickyBox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
