import React, { useState } from "react";
import Nouislider from "nouislider-react";
import { currencyFormatter } from "../components/formatter";

const ResultFiltersBudget = (props) => {
  const [textValue, setTextValue] = useState({ min: 0, max: 120000 });

  const onSlide = (render, handle, value, un, percent) => {
    setTextValue({ min: parseInt(value[0]), max: parseInt(value[1]) });
  };

  const onChange = (value) => {
    let val = { min: parseInt(value[0]), max: parseInt(value[1]) };
    props.handleFilters("budget", val);
  };

  return (
    <div className="filters-duration">
      <h4>Budget</h4>
      <div className="filters-slider">
        <Nouislider
          connect
          start={[
            props?.filters?.priceMin || 1,
            props?.filters?.priceMax || 120000,
          ]}
          behaviour="tap"
          range={{
            min: [1],
            max: [500000],
          }}
          onSlide={onSlide}
          onChange={onChange}
        />
        <div className="row mt-4">
          <div className="col-6 text-secondary small">
            Min. {currencyFormatter(props?.filters?.priceMin || textValue?.min)}
          </div>
          <div className="col-6 text-secondary small text-end">
            Max. {currencyFormatter(props?.filters?.priceMax || textValue?.max)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultFiltersBudget;
