import React, { useState } from "react";
import SortIcon from "../assets/images/sort_icon.svg";
import SortIconArrow from "../assets/images/sort_icon_arrow.svg";

const CustomDropdown = (props) => {
  const [isOpen, setOpen] = useState(false);
  const [active, setActive] = useState("");

  const setToggle = () => {
    setOpen(!isOpen);
  };

  const setClose = (act) => {
    setActive(act);
    setOpen(!isOpen);
    props.handleSorting(act);
  };

  return (
    <div className="custom-dropdown">
      <button className="btn custom-dropdown-btn" onClick={setToggle}>
        <img src={SortIcon} alt="Sort by" />
        <span>Sort by</span>
        <img
          src={SortIconArrow}
          className={"custom-dropdown-arrow-" + (isOpen ? "up" : "down")}
          alt=""
        />
      </button>

      {isOpen && (
        <ul className="list-unstyled">
          <li
            onClick={() => setClose("Price Low to High")}
            className={active === "Price Low to High" ? "active" : ""}
          >
            Price Low to High
          </li>
          <li
            onClick={() => setClose("Price High to Low")}
            className={active === "Price High to Low" ? "active" : ""}
          >
            Price High to Low
          </li>
          <li
            onClick={() => setClose("Duration Short to Long")}
            className={active === "Duration Short to Long" ? "active" : ""}
          >
            Duration Short to Long
          </li>
          <li
            onClick={() => setClose("Duration Long to Short")}
            className={active === "Duration Long to Short" ? "active" : ""}
          >
            Duration Long to Short
          </li>
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
